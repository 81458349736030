.titulo-video {
  color: #9cc11b;
  font-size: 20px;
  text-align: justify;
  text-decoration: none;
}

.titulo-video:hover {
  text-decoration: underline;
  color: #9cc11b;
}

.texto-video {
  white-space: break-spaces;
  font-size: 16px;
  color: #b5b4b4;
  margin: 5px 0 10px;
  text-align: justify;
  font-family: Roboto;
}
